import React from 'react'

import PropTypes from 'prop-types'
import { graphql } from 'gatsby'

import { MainLayout } from 'src/components/layout/main-layout'

import { RichText } from 'src/components/contentful/rich-text'

export default function Page({
  data: {
    contentfulPage: page,
    contentfulNavigation: {
      items
    }
  },
  location
}) {
  const {
    body,
    meta,
    padding,
    type
  } = page

  return (
    <MainLayout
      navigationItems={items}
      location={location}
      meta={meta}
      padding={padding}
      hasSidebar={type === 'documentation'}
    >
      {body && (
        <RichText body={body} />
      )}
    </MainLayout>
  )
}

Page.propTypes = {
  data: PropTypes.shape({
    contentfulNavigation: PropTypes.shape({
      items: PropTypes.arrayOf(PropTypes.shape({})).isRequired
    }).isRequired,
    contentfulFooter: PropTypes.shape({
      columns: PropTypes.shape({}).isRequired
    }),
    contentfulPage: PropTypes.shape({
      meta: PropTypes.shape({}).isRequired,
      body: PropTypes.shape({})
    })
  }).isRequired
}

export const query = graphql`
  query($slug: String!) {
    contentfulNavigation(navigationId: {eq: "main"}) {
      items {
        ...ContentfulNavigationItem
        subNavigationItems {
          ...ContentfulNavigationItem
        }
      }
    }

    contentfulFooter {
      columns {
        ... on ContentfulFooterColumn {
          title
          items {
            ... on ContentfulFooterItem {
              title
              externalUrl
            }
          }
        }
      }
    }

    contentfulPage(slug: {eq: $slug}) {
      meta {
        pageTitle
        metaDescription
        identifier
        ogTitle
        ogDescription
        ogImage {
          file {
            url
          }
        }
        twitterTitle
        twitterDescription
        twitterImage {
          file {
            url
          }
        }
      }
      padding
      type
      body {
        raw
        references {
          ... on ContentfulAsset {
            __typename
            contentful_id
            id
            title
            file {
              url
            }
          }
          ... on ContentfulWidgetHelp {
            widgetType: __typename
            contentful_id
            title
            subtitle
            descriptionHtml {
              raw
            }
            feedback {
              raw
            }
            image {
              file {
                url
              }
            }
          },
          ... on ContentfulWidgetHomepageHero {
            widgetType: __typename
            contentful_id
            widgetSize
            welcome {
              welcome
            }
            summary {
              summary
            }
            callToAction {
              callToAction
            }
            button
            image {
              file {
                url
              }
            }
          }
          ... on Node {
            ... on ContentfulCodeBlock {
              widgetType: __typename
              contentful_id
              description
              languages
              code {
                code
              }
              includeLineNumbers
            }
          }
          ... on ContentfulWidgetTestimonials {
            widgetType: __typename
            contentful_id
            items {
              image {
                file {
                  url
                }
              }
              description {
                description
              }
              author {
                author
              }
            }
          }
          ... on ContentfulWidgetUseCases {
            widgetType: __typename
            contentful_id
            useCasesTitle: title {
              title
            }
            useCasesSubtitle: subtitle {
              subtitle
            }
            subtitleLink
            items {
              icon {
                title
                file {
                  url
                }
              }
              title {
                title
              }
              description {
                description
              }
              slug
            }
          }
          ... on ContentfulWidgetPageNotFound {
            widgetType: __typename
            contentful_id
            title
            subtitle
            image {
              file {
                url
              }
            }
          }
          ... on Node {
            ... on ContentfulPostmanMarkdown {
              widgetType: __typename
              contentful_id
              postmanMarkdownButton {
                postmanMarkdownButton
              }
            }
          }
        }
      }
    }
  }
`
